import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import Home from "./components/Home.js";
import ProductDetail from "./components/ProductDetail.js";
import FilterProduct from "./components/FilterProduct.js";
import CheckOut from "./components/CheckOut.js";
import OrderThankyouPage from "./components/OrderThankyouPage.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Provider } from "react-redux";
import {store} from "./store/index";
import { VENDOR_URL } from "./Constants.js";
import { fetchContentData } from "./store/ContentAction.js";
import { fetchVenueInfo } from "./store/slice/venueSlice.js";
import { fetchMenuFeedApi } from "./store/MenuFeedaction.js";
import { fetchMerchantInfo } from "./store/actions.js";
import { getDiscountData } from "./store/discountAction.js";
import { getSdpData } from "./store/SdpAction.js";
import Sitemap from "./components/Sitemap.js";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/:brands?/:product_id",
        element: <ProductDetail />
      },
      {
        path: "/checkout",
        element: <CheckOut />
      },
      {
        path: "/thank-you",
        element: <OrderThankyouPage />
      },
      {
        path: "/products",
        element: <FilterProduct />
      },
      {
        path: "/sitemap.xml",
        element: <Sitemap />
      }
    ]
  }
], {
  basename: window.posabitmenuwidget.config.basename
}
);

// Check Query Params for overrides and set them in local storage
const queryParams = new URLSearchParams(window.location.search);

if(queryParams.has("merchant_token")){
  sessionStorage.setItem('merchantToken', queryParams.get("merchant_token"));
} else {
  sessionStorage.setItem('merchantToken', window.posabitmenuwidget.config.merchant_token);
}

if(queryParams.has("venue_slug")){
  sessionStorage.setItem('selectedVenue', queryParams.get("venue_slug"));
} else {
  sessionStorage.setItem('selectedVenue', window.posabitmenuwidget.config.venue_slug);
}

if(queryParams.has("merchant_slug")){
  sessionStorage.setItem('merchantSlug', queryParams.get("merchant_slug"));
} else {
  sessionStorage.setItem('merchantSlug', window.posabitmenuwidget.config.merchant_slug);
}

if(queryParams.has("initial_feed")){
  sessionStorage.setItem('feedId', queryParams.get("initial_feed"));
} else {
  sessionStorage.setItem('feedId', window.posabitmenuwidget.config.initial_feed_id);
}

if(queryParams.has("preview_mode")){
  sessionStorage.setItem('previewMode', queryParams.get("preview_mode"));
} else {
  sessionStorage.setItem('previewMode', window.posabitmenuwidget.config.preview_mode || false);
}

let apiHost = "";

if( window.posabitmenuwidget.config.server_host ) {
  apiHost = window.posabitmenuwidget.config.server_host;
} else {
  // This should not be needed, but just in case.  envrironment is deprecated.
  let environment = "development"
  apiHost = VENDOR_URL
  if(queryParams.has("environment")){
    environment = queryParams.get("environment");
  } else {
    environment = window.posabitmenuwidget.config.environment;
  }
  
  if( environment === "development"){
    apiHost = "http://localhost:3000";
  } else if( environment === "vendor"){
    apiHost = VENDOR_URL;
  } else if (environment === "qa") {
    apiHost = "https://qa-app.posabit.com";
  } else {
    apiHost = "https://app.posabit.com";
  }
}

sessionStorage.setItem('apiHost', apiHost);

async function main() {

  store.dispatch(fetchMerchantInfo());
  store.dispatch(fetchVenueInfo());
  store.dispatch(fetchContentData());
  store.dispatch(fetchMenuFeedApi());
  store.dispatch(getDiscountData());
  store.dispatch(getSdpData());

};

main();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

