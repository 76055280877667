import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMenuFeed } from "../store/slice/MenuFeedAPISlice";
import { useLocation } from "react-router-dom";
import { List, message } from "antd";
import ProductsScroll from "./ProductsScroll";
import { parseFiltersfromURL } from "../utility/productUtils";
import { filterNames, applyFilters } from "../utility/filterUtils";
import LoadingCard from "./LoadingCard";

const FilterProduct = () => {
  const location = useLocation();
  const storedFilters = location.state?.filters;
  const filterKey = useSelector((state) => state?.content?.contentData?.filters) || [];
  const [activeFilters, setActiveFilters] = useState(storedFilters ? storedFilters : parseFiltersfromURL(filterKey.map(f => f.filter_type)));

  const menuFeedData = useSelector(selectMenuFeed);
  const [ready, setReady] = useState(false);
  const sdpData = useSelector((state) => state?.sdpData?.info?.categories);
  const { loading, error } = useSelector((state) => state.menuFeed);
  const filterData = filterNames(activeFilters, menuFeedData?.product_data, sdpData);

  const [filteredProducts, setFilteredProducts] = useState([]);


  useEffect(() => {
    setActiveFilters(storedFilters || parseFiltersfromURL(filterKey.map(f => f.filter_type)))
  }, [menuFeedData, storedFilters, filterKey])

  useEffect(() => {
    setFilteredProducts(applyFilters(menuFeedData?.product_data, activeFilters))
    setTimeout(() => {
      setReady(true);
    }, 2000)
  }, [menuFeedData, activeFilters])


  if (loading) {
    return <List
    loading={loading}
    grid={{
      gutter: 2,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 3,
      xl: 4,
      xxl: 4,
    }}
    dataSource={['1','2','3','4','5','6','7','8','9','10']}
    renderItem={() => (
      <List.Item>
        <LoadingCard />
      </List.Item>
    )}
    />
  }

  if (error) {
    return message.error({
      content:"Something went wrong! Please try again later.",
      icon: <i className='fa fa-exclamation-circle' style={{color: 'red'}}/>
    });
  }

  const title = filterData.title ? `${filterData.title}` : "Filtered Products";

  return (
    <>
     <ProductsScroll products={filteredProducts} title={title} filtersToShow={filterData["secondary"]} activeFilters={activeFilters} ready={ready} />
    </>
  );
};

export default FilterProduct;
